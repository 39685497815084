:root {
  --base-color: #063;
  --second-color: #878787;
  --text-color: #0f0f0f;
  --background-color: #f8f9f9;
  --background-darken: #eee;
  --max-width-wrapper: 1024px;
  --padding-y-wrapper: 40px;
  --header-height: 88px;
  --slider-nav-height: 70px;
  --background-gradient: linear-gradient(
    0deg,
    rgba(255, 239, 38, .25) .04%,
    rgba(227, 49, 45, .25) 100%
  );
}

@media screen and (max-width: 1023px) {
  :root {
    --header-height: 60px;
    --slider-nav-height: 60px;
  }
}
* {
  box-sizing: border-box;
}

a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
  line-height: inherit !important;
  text-decoration: none !important;
}

html,
body {
  margin: 0;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}

main {
  overflow-x: hidden;
}

.wrapper {
  position: relative;
  display: block;
  width: 100%;
  max-width: var(--max-width-wrapper);
  margin: 0 auto;
}

a {
  display: inline-block;
}
p {
  margin: 0;
}

.non-scrollable {
  overflow: hidden;
}

.path {
  animation: dash 10s linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}
.scaled {
  animation: scaled 8s linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}

@keyframes scaled {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}
.animate__pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: block;
  width: 100%;
  height: var(--header-height);
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}
.header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.logo {
  z-index: 10;
  display: inline-block;
  min-width: 110px;
  min-height: 56px;
  padding: 17px 0 15px;
  overflow: hidden;
}

.nav {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  margin: 0 34px 0 77px;
}
.nav__list {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.nav__item {
  color: rgba(0, 0, 0, 0.25);
}
.nav__item:last-child {
  margin-right: 0;
}
.nav__item--active {
  color: var(--base-color);
}
.nav__link {
  position: relative;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  color: inherit;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s linear;
}
.nav__link:hover {
  color: var(--base-color);
}

.langs {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
.langs__link {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: opacity 0.15s linear;
}
.langs__link svg {
  width: 100%;
  height: 100%;
}
.langs__link:last-child {
  margin-right: 0;
}
.langs__link:hover {
  opacity: 1;
}
.langs__link--active {
  opacity: 1;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #063 !important;
}

.hamburger-box {
  width: 20px;
  height: 14px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 2px;
  background-color: #063 !important;
  border-radius: 0;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -6px;
}
.hamburger-inner::after {
  bottom: -6px;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 6px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -40px;
  top: -40px;
  transform: translate3d(40px, 40px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -40px;
  top: -40px;
  transform: translate3d(-40px, 40px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger {
  display: none;
  outline: 0;
}

@media only screen and (max-width: 1023px) {
  .header {
    padding: 0 10px;
  }
  .logo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 79px;
    min-width: 79px;
    height: 41px;
    min-height: 41px;
    margin: auto;
    padding: 0;
  }
  .logo img {
    width: 100%;
    height: 100%;
  }

  .hamburger {
    display: inline-block;
  }

  .nav {
    display: none;
    transition: all 0.5s ease-in;
  }
  .nav--open {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    margin-top: var(--header-height);
    padding-bottom: var(--header-height);
    background-color: #fff;
  }
  .nav--open ul {
    flex-wrap: wrap;
    width: 100%;
    height: auto;
  }
  .nav__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 15px 20px;
    font-size: 24px;
    line-height: 28;
    text-align: left;
  }
  .nav__item:first-child {
    padding-top: 0;
  }
  .nav__item a {
    width: 100%;
    font-size: 24px;
    line-height: 28px;
  }

  .langs {
    display: none;
  }
  .langs__link {
    width: 30px;
    height: 30px;
  }
  .langs--open {
    position: fixed;
    top: unset;
    bottom: 30px;
    left: 20px;
    display: flex;
    height: 30px;
  }
}
.first {
  position: relative;
  display: block;
  width: 100%;
  height: calc(100vh - var(--header-height));
  max-height: 568px;
  background-color: var(--background-color);
}

.glide {
  width: 100%;
  height: 100%;
  margin: 0;
}
.glide__track {
  height: calc(100% - var(--slider-nav-height));
}
.glide__slider, .glide__slides {
  width: 100%;
  height: 100%;
  margin: 0;
}
.glide__content {
  display: flex;
  align-items: center;
  height: calc(100% - 30px);
  margin: 30px auto 0;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: contain;
}
.glide__title {
  max-width: 511px;
  margin: 0;
}
.glide__title span {
  color: var(--base-color);
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  text-transform: uppercase;
}
.glide__title span:first-child {
  font-size: 88px;
  line-height: 107px;
}
.glide__title span:last-child {
  color: var(--second-color);
}
.glide__bullets {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--slider-nav-height);
  overflow: hidden;
}
.glide__bullet {
  margin-right: 48px;
  color: var(--text-color);
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.15s linear;
}
.glide__bullet:hover {
  color: var(--base-color);
}
.glide__bullet--active {
  color: var(--base-color);
}
.glide__bullet:last-child {
  margin-right: 0;
}
.glide__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 512px;
  height: 100%;
  max-height: 467px;
  margin: auto;
  object-fit: contain;
}

@media only screen and (max-width: 1023px) {
  .glide__content {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .glide__title {
    display: block;
    width: 100%;
    max-width: unset;
    margin: 25px 0 0;
    text-align: center;
  }
  .glide__title span {
    display: block;
    font-size: 30px;
    line-height: 37px;
  }
  .glide__title span:first-child {
    display: block;
    width: 100%;
    font-size: 55px;
    line-height: 67px;
  }
  .glide__title span:last-child {
    color: var(--second-color);
  }
  .glide__bullet {
    margin-right: 0;
    font-size: 14px;
    line-height: 18px;
  }
  .glide__img {
    position: relative;
    width: 50%;
    width: 260px;
    max-width: 260px;
    height: auto;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .first {
    max-height: unset;
  }
}
@media only screen and (max-width: 767px) and (orientation: landscape) {
  .first {
    height: auto;
  }
}
.title {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 40px 0 46px;
  color: var(--base-color);
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  text-transform: uppercase;
}
.title::before {
  position: absolute;
  top: -7px;
  bottom: 0;
  left: -13px;
  z-index: -1;
  display: block;
  width: 60px;
  height: 60px;
  margin: auto;
  background-image: var(--background-gradient);
  border-radius: 50%;
  content: "";
}

@media screen and (max-width: 1023px) {
  .title {
    color: var(--base-color);
    font-size: 24px;
    line-height: 36px;
  }
}
.about {
  padding-bottom: 65px;
}
.about__container {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.about__text {
  max-width: 512px;
  color: var(--text-color);
  font-size: 16px;
  line-height: 24px;
}
.about__text b {
  display: block;
  padding-top: 25px;
  color: var(--base-color);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1023px) {
  .about {
    padding-bottom: 35px;
  }
  .about__container {
    flex-direction: column;
  }
  .about__text {
    max-width: 100%;
    padding: 0 20px;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
  .about__text b {
    font-size: 16px;
    line-height: 24px;
  }
  .about__map {
    padding: 0 20px;
  }
  .about__map > svg {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .about__map > svg {
    width: 100%;
  }
}
.portfolio {
  background-color: var(--background-color);
  --portfolio-item-box: 210px;
}
.portfolio__list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.portfolio__item {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 210px;
  background-color: white;
  border-radius: 15px;
  filter: drop-shadow(0 4px 20px rgba(120, 120, 120, 0.15));
}
.portfolio__item:hover {
  -webkit-animation-name: heartbeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.portfolio__item::after {
  position: relative;
  z-index: -1;
  width: 77.4%;
  height: 77.4%;
  background-image: var(--background-gradient);
  border-radius: 50%;
  content: "";
}
.portfolio__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50.4%;
  height: 50.4%;
  margin: auto;
  background-image: url("../images/icons/phone.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  content: "";
}
.portfolio__item--small {
  z-index: 0;
  width: 120px;
  height: 120px;
}
.portfolio__item--medium {
  z-index: 1;
  width: 155px;
  height: 155px;
}
.portfolio__item:nth-child(1) {
  left: 54px;
}
.portfolio__item:nth-child(1)::before {
  background-image: url("../images/icons/laptop.svg");
}
.portfolio__item:nth-child(2) {
  left: 27px;
}
.portfolio__item:nth-child(2)::before {
  background-image: url("../images/icons/pad.svg");
}
.portfolio__item:nth-child(4) {
  left: -27px;
}
.portfolio__item:nth-child(4)::before {
  left: 4px;
  background-image: url("../images/icons/watch.svg");
}
.portfolio__item:nth-child(5) {
  left: -54px;
}
.portfolio__item:nth-child(5)::before {
  background-image: url("../images/icons/monoblock.svg");
}
.portfolio__text {
  padding: 30px 0 47px;
  color: var(--text-color);
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

@media screen and (max-width: 1023px) {
  .portfolio__item--small {
    display: flex;
  }
  .portfolio__item--medium {
    display: flex;
  }
  .portfolio__text {
    padding: 30px 20px;
    text-align: justify;
  }
}
@media screen and (max-width: 767px) {
  .portfolio__item {
    width: 90px;
    height: 90px;
  }
  .portfolio__item--small {
    width: 51px;
    height: 51px;
  }
  .portfolio__item--medium {
    width: 66px;
    height: 66px;
  }
  .portfolio__item:nth-child(1) {
    left: 22px;
  }
  .portfolio__item:nth-child(2) {
    left: 11px;
  }
  .portfolio__item:nth-child(4) {
    left: -11px;
  }
  .portfolio__item:nth-child(4)::before {
    left: 4px;
  }
  .portfolio__item:nth-child(5) {
    left: -22px;
  }
}
.why__container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 63px;
}
.why__box {
  position: relative;
  padding: 31px 0 40px 44px;
  color: var(--text-color);
  transition: all 0.5s linear;
}
.why__box:hover > p, .why__box:hover > strong {
  transform: scale(1.05) !important;
}
.why__box::before {
  position: absolute;
  top: 0;
  left: -13px;
  z-index: -1;
  display: block;
  width: 88px;
  height: 88px;
  margin: auto;
  color: white;
  font-weight: bold;
  font-size: 66px;
  line-height: 80px;
  background-image: var(--background-gradient);
  border-radius: 50%;
  content: "1";
}
.why__box:nth-child(2)::before {
  content: "2";
}
.why__box:nth-child(3)::before {
  content: "3";
}
.why__box:nth-child(4)::before {
  content: "4";
}
.why__title {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 9px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
.why__text {
  max-width: 405px;
  padding-right: 50px;
  font-size: 18px;
  line-height: 22px;
}
.why__text--long {
  padding-right: 5px;
}

@media only screen and (max-width: 1023px) {
  .why__container {
    padding: 0 20px;
  }
  .why__box {
    padding: 25px 0 20px 30px;
  }
  .why__box::before {
    width: 66px;
    height: 66px;
  }
  .why__title {
    font-size: 24px;
    line-height: 29px;
  }
  .why__text {
    width: 100%;
    max-width: 100%;
    padding-right: unset;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
  .why__text--long {
    padding-right: unset;
  }
}
.partners {
  background-color: var(--background-color);
}
.partners__container {
  display: flex;
  flex-wrap: wrap;
}
.partners__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 288px;
  margin-bottom: 50px;
  padding-top: 78px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: initial;
}
.partners__box img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
}
.partners__box:hover {
  transform: scale(1.05) !important;
}
.partners__box:nth-child(2) {
  margin: 0 80px;
}
.partners__box:nth-child(5) {
  margin: 0 80px;
}

@media only screen and (max-width: 1023px) {
  .partners__box {
    justify-content: center;
    margin: 0 auto 40px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .partners__box:nth-child(2) {
    margin: 0 auto 40px;
  }
  .partners__box:nth-child(5) {
    margin: 0 auto 40px;
  }
}
.mission__container {
  margin-bottom: 50px;
}
.mission__box {
  position: absolute;
  top: -93px;
  right: 0;
}
.mission__img {
  position: relative;
  display: block;
  padding-top: 34px;
}
.mission__img:nth-child(2) {
  position: absolute;
  top: 0;
  bottom: 70px;
  left: 30px;
  z-index: 0;
  margin: auto 0;
}
.mission__img:nth-child(3) {
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: auto 0;
}
.mission__text {
  max-width: 512px;
  font-size: 16px;
  line-height: 24px;
}
.mission__text b {
  display: block;
  color: var(--base-color);
}

@media only screen and (max-width: 1023px) {
  .mission__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .mission__box {
    position: relative;
    top: 0;
    right: 0;
    height: auto;
    margin: 0 auto;
  }
  .mission__text {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
    text-align: justify;
  }
}
.contacts {
  padding-bottom: 95px;
  background-color: var(--background-color);
}
.contacts__container {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.contacts__title {
  max-width: 357px;
  padding-bottom: 24px;
  color: var(--base-color);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
.contacts__subtitle {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}
.contacts__text {
  padding-bottom: 20px;
  font-size: 12px;
  line-height: 15px;
}
.contacts__map {
  width: 512px;
  height: 266px;
  overflow: hidden;
}

@media only screen and (max-width: 1023px) {
  .contacts {
    padding-bottom: 0;
  }
  .contacts__container {
    flex-direction: column;
  }
  .contacts__data {
    padding: 0 20px;
  }
  .contacts__title {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
  .contacts__subtitle {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
  .contacts__text {
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
  .contacts__map {
    width: 100%;
    height: 266px;
    overflow: hidden;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0 33px;
  color: var(--second-color);
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background-color: var(--background-darken);
}
.footer a {
  color: var(--base-color);
  text-decoration: none;
}

@media only screen and (max-width: 1023px) {
  .footer {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1023px) {
  #back-to-top {
    right: 10px !important;
    z-index: 10 !important;
  }
}